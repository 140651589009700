import { IMatchCardData } from '@unionco/alaris-app-types'

import { UserRoles } from '@unionco/alaris-utils'

import { cx } from '@unionco/utils'

import { Img } from 'components'

interface IMatchLinkProps {
  data: IMatchCardData
  isTopMatch?: boolean
  companyTypeForHref?: 'buyer' | 'seller'
}

export const MatchLink: React.FC<IMatchLinkProps> = ({
  data
}) => {
  const companyDisplayName = data.companyName || data.name || ''
  const companyDisplayLogo = data.companyLogo || data.logo || ''
  const matchImage = (url: string) => {
    // Default Alaris image if company logo cannot be retrieved
    let img = 'https://alaris-web.s3.amazonaws.com/flourish1_1_dc0936c6af.png'
    if (url) {
      img = url
    }
    return (
      <div className='u-h-12 u-min-w-[48px] u-w-12 u-min-h-[48px] u-content-center u-bg-white u-border-white u-rounded-full u-shadow-[4px_4px_24px_0px_rgba(100,115,129,0.15)]'>
        <Img src={img} width={48} height={48} alt='' />
      </div>
    )
  }

  // create match score colors
  const matchScoreIndicator = (score: string) => {
    let bgColor = ''
    let textColor = ''

    switch (score?.toLocaleLowerCase()) {
      case 'optimal':
        bgColor = 'u-bg-[rgba(0,3,173,0.10)]'
        textColor = 'u-text-[#0000AD]'
        break;

      case 'strong':
        bgColor = 'u-bg-[rgba(0,173,3,0.10)]'
        textColor = 'u-text-[#00AD03]'
        break;

      case 'moderate':
        bgColor = 'u-bg-[rgba(255,150,1,0.10)]'
        textColor = 'u-text-[#FF9601]'
        break;

      case 'weak':
        bgColor = 'u-bg-[rgba(255,0,0,0.10)]'
        textColor = 'u-text-[#FF0000]'
        break;
      default:
        bgColor = 'u-bg-[rgba(100,115,129,0.10)]'
        textColor = 'u-text-newsecondary-500'
        break;
    }
    return (
      <div className={cx('u-flex u-w-24 u-px-4 u-py-2 u-justify-center u-rounded-3xl u-aspect-[2/1]', bgColor)}>
        <p className={cx('u-text-[14.4px]', textColor)}>{score ? score : 'Unknown'}</p>
      </div>)
  }
  return (
    <div className='print:u-w-1/3 print:u-flex print:u-flex-row'>
      <div
        onClick={() => window.location.href = `/buyer-at-a-glance?companyId=${data.buyerId}`}
        style={{ cursor: 'pointer' }}
        key={data.buyerId}
        className='o-body-1 u-items-center u-rounded u-bg-primary-300 u-p-400'
      >
        <div className='u-flex u-flex-row u-h-12'>
          <div className='u-flex u-flex-row u-items-center u-gap-4'>
            {matchImage(companyDisplayLogo)}
            <p className='lens-paragraph-small u-text-left u-text-black u-text-[14.4px] u-line-clamp-2 u-leading-[22.6px]'>{companyDisplayName}</p>
          </div>
          <div className='u-flex u-items-center u-ml-auto u-pl-1'>
            {matchScoreIndicator(data.matchScore)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MatchLink
