import React from 'react'

import { IHistoricGrowthData } from '@unionco/alaris-app-types'

import { ModuleCard } from 'components/layout'

export interface IHistoricGrowthProps {
  data: IHistoricGrowthData
}

export const HistoricGrowth: React.FC<IHistoricGrowthProps> = ({
  data: {
    historicGrowthData,
    netNewAssetGrowth,
    historicGrowthParagraph,
    revenueAndEbitdaTrend,
  }
}) => {
  if (!revenueAndEbitdaTrend && !historicGrowthParagraph && !netNewAssetGrowth && historicGrowthData.length === 0) return <></>

  return (
    <div className='u-flex u-flex-col u-gap-4'>
      <h5>Net New Assets</h5>

      {/* Net New Asset Growth Data */}
      {historicGrowthData && historicGrowthData.length > 0 && (
        <ModuleCard.Body type={'metric'} metricContent={historicGrowthData} />
      )}

      {/* Net New Asset Growth Graph */}
      {netNewAssetGrowth && (
        <div>
          <ModuleCard.Body useNewStyling={true} interiorHeading='NNA Growth' type='bar-chart' chartContent={netNewAssetGrowth} yAxisFormat='percent' />
        </div>
      )}
    </div>

  )
}
export default HistoricGrowth