import type { IOrganicGrowthData } from '@unionco/alaris-app-types'

import { CheckMarkListItem, ModuleCard, Video } from 'components'

interface IOrganicGrowthProps {
  className?: string
  data: IOrganicGrowthData
}

export const OrganicGrowth: React.FC<IOrganicGrowthProps> = ({
  className,
  data: { info, items, title, video }
}) => {
  if (!items || items.length === 0) return <></>
  title = title || 'Organic Growth'
  return (
    <div>
      <h5 className="u-self-start u-pb-6">{title}</h5>
      <ModuleCard.Body className={` u-text-left u-min-w-[26rem] u-flex-grow-[3] ${className}`} useNewStyling={true}>
        {video && <Video className='print:u-hidden' data={video} />}
        <div className='c-autoGrid'>
          {items.sort((a, b) => a.title.length - b.title.length).map((item, index) => {
            return (
              <CheckMarkListItem
                className={'u-py-2'}
                data={item}
                key={`OrganicGrowth_${index}`}
              />
            )
          })}
        </div>
      </ModuleCard.Body>
    </div>
  )
}

export default OrganicGrowth
