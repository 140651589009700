/** Module Card Body
 *  Card instance styled specifically for Modules
 */
import { Card } from '@unionco/components'
import React from 'react'
import tw, { TwStyle } from 'twin.macro'

import {
  IAutonomyProfileStoryData,
  IBasicMetricData,
  IClientExperienceStoryData,
  IGenerationalSummaryByAUM,
  ISeekingPartnershipStoryData,
  TBarChartData,
  TFlowInnerSpacingClass,
  TLineChartData,
  TPaddingTokenClass
} from '@unionco/alaris-app-types'

import { currencyAbbr, cx } from '@unionco/utils'

import { BarChart } from 'components/Chart'
import { Img, Video } from 'components/generic'
import { MetricCard } from 'components/modules'
import TrendingRevenue from 'components/modules/ClientDemographics/TrendingRevenue'
import { customTooltip } from 'components/modules/customTooltip'
import BulletIcon from 'components/svg/icons/bullet/Bullet'

export type IModuleCardBodyProps =
  | IModuleCardBodyMetricProps
  | IModuleCardBodyDefaultProps
  | IModuleCardBodyBarChartProps
  | IModuleCardBodyLineChartProps
  | IModuleCardVideoProps
  | IModuleCardDescriptionVideoProps
  | IModuleCardTableProps
  | IModuleCardTopMatchesProps

export interface IModuleCardBodyBaseProps {
  bgColorClass?: TwStyle
  bgClassName?: string
  children?: React.ReactNode
  className?: string
  flowspace?: TFlowInnerSpacingClass
  padding?: TPaddingTokenClass | null
  innerCardPadding?: string | string[]
  useNewStyling?: boolean
  interiorHeading?: string
  videoContent?: IClientExperienceStoryData | ISeekingPartnershipStoryData | IAutonomyProfileStoryData
  matchesContent?: any[]
}

export interface IModuleCardBodyMetricProps extends IModuleCardBodyBaseProps {
  type: 'metric' | 'metric-stacked'
  layout?: 'column' | 'row'
  metricContent: IBasicMetricData[]
  chartContent?: undefined
  tableContent?: undefined
  columnHeaders?: undefined
  indexAxis?: undefined
  yAxisFormat?: undefined
  xAxisFormat?: undefined
  matchesContent?: any[]
}

export interface IModuleCardBodyBarChartProps extends IModuleCardBodyBaseProps {
  type: 'bar-chart'
  indexAxis?: 'x' | 'y'
  yAxisFormat?: 'label' | 'currency' | 'percent'
  xAxisFormat?: 'label' | 'currency' | 'percent'
  metricContent?: undefined
  layout?: undefined
  chartContent: TBarChartData
  tableContent?: undefined
  columnHeaders?: undefined
  videoContent?: undefined
  matchesContent?: any[]
}

export interface IModuleCardBodyLineChartProps
  extends IModuleCardBodyBaseProps {
  type: 'line-chart'
  metricContent?: undefined
  layout?: undefined
  chartContent: TLineChartData
  tableContent?: undefined
  columnHeaders?: undefined
  indexAxis?: undefined
  yAxisFormat?: undefined
  xAxisFormat?: undefined
  videoContent?: undefined
  matchesContent?: any[]
}

export interface IModuleCardTableProps extends IModuleCardBodyBaseProps {
  type: 'table'
  tableContent: IGenerationalSummaryByAUM[]
  metricContent?: undefined
  layout?: undefined
  columnHeaders: string[]
  chartContent?: TBarChartData
  indexAxis?: undefined
  yAxisFormat?: undefined
  xAxisFormat?: undefined
  videoContent?: undefined
  matchesContent?: any[]
}

export interface IModuleCardVideoProps extends IModuleCardBodyBaseProps {
  type: 'video'
  videoContent?: undefined
  tableContent?: undefined
  metricContent?: undefined
  layout?: undefined
  columnHeaders?: undefined
  chartContent?: undefined
  indexAxis?: undefined
  yAxisFormat?: undefined
  xAxisFormat?: undefined
  matchesContent?: any[]
}

export interface IModuleCardDescriptionVideoProps
  extends IModuleCardBodyBaseProps {
  type: 'description-video'
  videoContent: IClientExperienceStoryData | ISeekingPartnershipStoryData | IAutonomyProfileStoryData
  tableContent?: undefined
  metricContent?: undefined
  layout?: undefined
  columnHeaders?: undefined
  chartContent?: undefined
  indexAxis?: undefined
  yAxisFormat?: undefined
  xAxisFormat?: undefined
  matchesContent?: any[]
}

export interface IModuleCardTopMatchesProps
  extends IModuleCardBodyBaseProps {
  type: 'top-matches'
  videoContent?: undefined
  tableContent?: undefined
  metricContent?: undefined
  layout?: undefined
  columnHeaders?: undefined
  chartContent?: undefined
  indexAxis?: undefined
  yAxisFormat?: undefined
  xAxisFormat?: undefined
  matchesContent: any[]
}

export interface IModuleCardBodyDefaultProps extends IModuleCardBodyBaseProps {
  type?: undefined
  layout?: undefined
  metricContent?: undefined
  chartContent?: undefined
  tableContent?: undefined
  columnHeaders?: undefined
  indexAxis?: undefined
  yAxisFormat?: undefined
  xAxisFormat?: undefined
  videoContent?: undefined
  matchesContent?: any[]
}

export const ModuleCardBody: React.FC<IModuleCardBodyProps> = ({
  bgColorClass = tw`u-bg-white`,
  children,
  className,
  bgClassName,
  flowspace = 'u-flow-space-inner--400',
  padding = 'u-p-500 print:u-p-0',
  innerCardPadding = 'u-p-8',
  useNewStyling = false,
  interiorHeading = '',
  metricContent = undefined,
  chartContent = undefined,
  tableContent = undefined,
  columnHeaders = undefined,
  indexAxis = 'x',
  yAxisFormat = 'currency',
  xAxisFormat = undefined,
  layout = 'row',
  type = undefined,
  videoContent = undefined,
  matchesContent = undefined
}) => {
  let content
  const scaleXStyling = {
    grid: {
      display: false
    }
  }

  const scaleYStyling = {
    border: {
      dash: [5, 8],
      display: false
    }
  }

  const dollarMeasurementStyles = {
    ticks: {
      callback: (value: string | number) => currencyAbbr(value)
    }
  }

  switch (type) {
    case 'metric':
      const layoutClass = layout === 'row' ? 'u-flex-row' : 'u-flex-col'
      content = (
        <Card
          className={cx('u-flex u-flex-col u-gap-2 u-p-2')}
          bgColorClass={tw`u-bg-opacity-40`}
          cssClasses={[
            tw`u-rounded-[32px] u-border u-border-solid u-border-newprimary-purewhite u-backdrop-blur-[100px]`
          ]}
        >
          {interiorHeading && (
            <h5 className='u-flex u-items-center u-justify-center u-self-center u-py-2 u-text-newsecondary-500'>
              {interiorHeading}
            </h5>
          )}
          <div className={`u-flex ${layoutClass} u-gap-2`}>
            {metricContent?.map((item, index) => (
              <Card
                key={`MetricCardContainer_${index}`}
                className={cx(`u-flex u-flex-1 ${className}`)}
                bgColorClass={tw`u-bg-opacity-60`}
                cssClasses={[
                  tw`u-rounded-[24px] u-border u-border-solid u-border-newprimary-purewhite u-backdrop-blur-[100px]`
                ]}
              >
                <MetricCard
                  data={item}
                  key={`MetricCard_${index}`}
                  useNewStyling={true}
                />
              </Card>
            ))}
          </div>
        </Card>
      )
      break
    case 'metric-stacked':
      content = (
        <Card
          className={cx('u-flex u-flex-col u-gap-2 u-p-2')}
          bgColorClass={tw`u-bg-opacity-40`}
          cssClasses={[
            tw`u-rounded-[32px] u-border u-border-solid u-border-newprimary-purewhite u-backdrop-blur-[100px]`
          ]}
        >
          {interiorHeading && (
            <h5
              className='u-flex u-items-center u-justify-center u-self-center u-py-2 u-text-newsecondary-500 u-text-center'
            >
              {interiorHeading}
            </h5>
          )}
          <div className={`u-flex u-flex-col u-gap-2 u-h-full`}>
            <Card
              className={cx('u-flex u-flex-col u-gap-8 u-p-8 u-h-full')}
              bgColorClass={tw`u-bg-opacity-60`}
              cssClasses={[
                tw`u-rounded-[24px] u-border u-border-solid u-border-newprimary-purewhite u-backdrop-blur-[100px]`
              ]}
            >
              {metricContent && (
                <MetricCard
                  data={metricContent}
                  stackedLayout={true}
                  key={`MetricCard_${metricContent}`}
                  useNewStyling={true}
                />
              )}
            </Card>
          </div>
        </Card>
      )
      break
    case 'bar-chart':
      innerCardPadding = Array.isArray(innerCardPadding)
        ? innerCardPadding.join(' ')
        : innerCardPadding

      const yAxisLabel =
        yAxisFormat === 'currency'
          ? dollarMeasurementStyles
          : yAxisFormat === 'percent'
            ? { ticks: { callback: (value: string | number) => `${value}%` } }
            : yAxisFormat === 'label'
              ? null
              : {};

      const xAxisLabel =
        xAxisFormat === 'currency'
          ? dollarMeasurementStyles
          : xAxisFormat === 'percent'
            ? { ticks: { callback: (value: string | number) => `${value}%` } }
            : xAxisFormat === 'label'
              ? null
              : {};

      let tooltipCallback: ((value: string | number) => string) | undefined = undefined;

      if (indexAxis === 'y') {
        tooltipCallback =
          xAxisFormat === 'label'
            ? undefined
            : xAxisFormat === 'percent'
              ? (value: string | number) => `${value}%`
              : typeof currencyAbbr === 'function'
                ? (value: string | number) => currencyAbbr(value)
                : (value: string | number) => `${currencyAbbr} ${value}`;
      } else {
        tooltipCallback =
          yAxisFormat === 'label'
            ? undefined
            : yAxisFormat === 'percent'
              ? (value: string | number) => `${value}%`
              : typeof currencyAbbr === 'function'
                ? (value: string | number) => currencyAbbr(value)
                : (value: string | number) => `${currencyAbbr} ${value}`;
      }


      content = (
        <Card
          className={cx('u-flex u-flex-col u-gap-2 u-p-2')}
          bgColorClass={tw`u-bg-opacity-40`}
          cssClasses={[
            tw`u-rounded-[32px] u-border u-border-solid u-border-newprimary-purewhite u-backdrop-blur-[100px]`
          ]}
        >
          {interiorHeading && (
            <h6
              className='u-flex u-items-center u-justify-center u-self-center u-py-2 u-text-newsecondary-500'
            >
              {interiorHeading}
            </h6>
          )}
          <Card
            key={`BarChartCardContainer_${chartContent?.labels}`}
            className={cx('u-flex u-flex-1 u-flex-col')}
            bgColorClass={tw`u-bg-opacity-40`}
            cssClasses={[
              tw`u-rounded-[24px] u-border u-border-solid u-border-newprimary-purewhite u-backdrop-blur-[100px]`
            ]}
          >
            {chartContent && (
              <div className='u-p-8'>
                <BarChart
                  data={chartContent as TBarChartData}
                  options={{
                    indexAxis,
                    scales: {
                      x: {
                        ...scaleXStyling,
                        ...xAxisLabel
                      },
                      y: {
                        ...scaleYStyling,
                        ...yAxisLabel
                      }
                    },
                    plugins: {
                      tooltip: {
                        enabled: false,
                        external: (context) =>
                          customTooltip(
                            context,
                            'threeYearAumTrend',
                            '',
                            tooltipCallback
                          )
                      }
                    },
                    animation: {
                      duration: 0
                    }
                  }}
                />
              </div>
            )}
          </Card>
          {chartContent?.paragraph?.description && (
            <Card
              className={cx(
                'c-flow u-h-[100%]',
                flowspace,
                innerCardPadding,
                className
              )}
              bgColorClass={tw`u-bg-opacity-60`}
              cssClasses={[
                tw`u-rounded-[24px] u-border u-border-solid u-border-newprimary-purewhite u-backdrop-blur-[100px]`
              ]}
            >
              <p className='lens-small-paragraph-text'>
                {chartContent.paragraph.description}
              </p>
            </Card>
          )}
        </Card>
      )
      break
    case 'line-chart':
      content = (
        <Card
          className={cx('u-flex u-flex-col u-gap-2 u-p-2')}
          bgColorClass={tw`u-bg-opacity-40`}
          cssClasses={[
            tw`u-rounded-[32px] u-border u-border-solid u-border-newprimary-purewhite u-backdrop-blur-[100px]`
          ]}
        >
          {interiorHeading && (
            <h6 className='u-flex u-items-center u-justify-center u-self-center u-py-2 u-text-newsecondary-500'>
              {interiorHeading}
            </h6>
          )}
          <Card
            key={`BarChartCardContainer_${chartContent?.labels}`}
            className={cx('u-flex u-flex-1 u-flex-col')}
            bgColorClass={tw`u-bg-opacity-60`}
            cssClasses={[
              tw`u-rounded-[24px] u-border u-border-solid u-border-newprimary-purewhite u-backdrop-blur-[100px]`
            ]}
          >
            <div className='u-p-8'>
              <TrendingRevenue
                data={chartContent as TLineChartData}
                options={{
                  scales: {
                    x: {
                      ...scaleXStyling
                    },
                    y: {
                      ...scaleYStyling
                    }
                  }
                }}
              />
            </div>
          </Card>
        </Card>
      )
      break
    case 'table':
      content = (
        <Card
          className={cx('u-flex u-flex-col u-gap-2 u-p-2')}
          bgColorClass={tw`u-bg-opacity-40`}
          cssClasses={[
            tw`u-rounded-[32px] u-border u-border-solid u-border-newprimary-purewhite u-backdrop-blur-[100px]`
          ]}
        >
          {interiorHeading && (
            <h5 className='u-flex u-items-center u-justify-center u-self-center u-py-2 u-text-newsecondary-500'>
              {interiorHeading}
            </h5>
          )}

          <Card
            key={`TableCardContainer`}
            className='u-px-8 u-pt-6 u-pb-2'
            bgColorClass={tw`u-bg-opacity-60`}
            cssClasses={[
              tw`u-rounded-[24px] u-border u-border-solid u-border-newprimary-purewhite u-backdrop-blur-[100px]`
            ]}
          >
            <div
              className={`u-grid u-grid-cols-${columnHeaders?.length} u-w-full u-text-center u-font-semibold`}
            >
              {columnHeaders?.map((header, index) => (
                <div
                  key={`header-${index}`}
                  className='u-col-span-1 u-pb-4 u-text-newsecondary-500'
                >
                  {header}
                </div>
              ))}

              {tableContent?.map((row, rowIndex) => (
                <React.Fragment key={`table-row-${rowIndex}`}>
                  <div
                    className={`u-py-6 ${rowIndex < tableContent.length - 1 ? 'u-border-b' : ''
                      }`}
                  >
                    {row.generationRange}
                  </div>
                  <div
                    className={`u-py-6 ${rowIndex < tableContent.length - 1 ? 'u-border-b' : ''
                      } u-text-newsecondary-500`}
                  >
                    {row.percentageOfRIAAUM}
                  </div>
                </React.Fragment>
              ))}
            </div>
          </Card>
        </Card>
      )
      break
    case 'video':
      padding = 'u-p-200 print:u-p-0'
      innerCardPadding = 'u-p-8'
      content = (
        <Card
          className={cx(
            'u-flex u-flex-col u-p-2',
            flowspace,
            innerCardPadding,
            className
          )}
          bgColorClass={tw`u-bg-newprimary-purewhite`}
          cssClasses={[
            tw`u-rounded-[32px] u-border u-border-solid u-border-newprimary-purewhite u-backdrop-blur-[100px]`
          ]}
        >
          {children}
        </Card>
      )
      break
    case 'description-video':
      padding = 'u-p-200 print:u-p-0'
      innerCardPadding = Array.isArray(innerCardPadding)
        ? innerCardPadding.join(' ')
        : innerCardPadding
      content = (videoContent && (
        <Card
          className={cx('c-flow u-gap-2', flowspace, padding, className)}
          bgColorClass={tw`u-bg-opacity-40`}
          cssClasses={[
            tw`u-rounded-[32px] u-border u-border-solid u-border-newprimary-purewhite u-backdrop-blur-[100px]`
          ]}
        >
          <Card
            className={cx(
              'u-flex u-h-[100%] u-flex-col u-p-2 ',
              flowspace,
              innerCardPadding,
              className
            )}
            bgColorClass={tw`u-bg-opacity-60`}
            cssClasses={[
              tw`u-rounded-[24px] u-border u-border-solid u-border-newprimary-purewhite u-backdrop-blur-[100px]`
            ]}
          >
            <div className='u-flex u-flex-col u-gap-4'>
              {(videoContent?.title || videoContent?.video?.title) && (
                <p className='lens-overline-text'>{videoContent?.title || videoContent?.video?.title}</p>
              )}
              {(videoContent?.value || videoContent?.copy) && (
                <div className="u-flex u-flex-row u-items-baseline u-gap-4">
                  <BulletIcon />
                  <p>{videoContent.value || videoContent.copy}</p>
                </div>
              )}
            </div>
            {videoContent?.video && (
              <div
                className={cx(
                  'u-flex u-h-[100%] u-flex-col u-m-0 u-pt-4',
                )}
              >
                {videoContent && <Video data={videoContent.video} videoOnly={true} />}
              </div>
            )}
          </Card>
        </Card >
      ))
      break
    case 'top-matches':
      const matchesToDisplay = 5

      const matchImage = (url: string) => {
        // Default Alaris image
        let img = 'https://alaris-web.s3.amazonaws.com/flourish1_1_dc0936c6af.png'
        if (url) {
          img = url
        }
        return (
          <div className='u-h-12 u-min-w-[48px] u-w-12 u-min-h-[48px] u-content-center u-bg-white u-border-white u-rounded-full u-shadow-[4px_4px_24px_0px_rgba(100,115,129,0.15)]'>
            <Img src={img} width={48} height={48} alt='' />
          </div>
        )
      }
      const matchScoreIndicator = (score: string) => {
        let bgColor = ''
        let textColor = ''

        switch (score?.toLocaleLowerCase()) {
          case 'optimal':
            bgColor = 'u-bg-[rgba(0,3,173,0.10)]'
            textColor = 'u-text-[#0000AD]'
            break;

          case 'strong':
            bgColor = 'u-bg-[rgba(0,173,3,0.10)]'
            textColor = 'u-text-[#00AD03]'
            break;

          case 'moderate':
            bgColor = 'u-bg-[rgba(255,150,1,0.10)]'
            textColor = 'u-text-[#FF9601]'
            break;

          case 'weak':
            bgColor = 'u-bg-[rgba(255,0,0,0.10)]'
            textColor = 'u-text-[#FF0000]'
            break;
          default:
            bgColor = 'u-bg-[rgba(100,115,129,0.10)]'
            textColor = 'u-text-newsecondary-500'
            break;
        }

        return (
          <div className={cx('u-flex u-w-24 u-px-4 u-py-2 u-justify-center u-rounded-3xl u-aspect-[2/1]', bgColor)}>
            <p className={cx('u-text-[14.4px]', textColor)}>{score ? score : 'Unknown'}</p>
          </div>)
      }
      content = (
        <Card
          className={cx('u-flex u-flex-col u-gap-2 u-p-2 ')}
          bgColorClass={tw`u-bg-opacity-40`}
          cssClasses={[
            tw`u-rounded-[32px] u-border u-border-solid u-border-newprimary-purewhite u-backdrop-blur-[100px]`
          ]}
        >
          {matchesContent && matchesContent.slice(0, matchesToDisplay).map((match) => (
            <div
              onClick={() => window.location.href = `/buyer-at-a-glance?companyId=${match.buyerId}`}
              style={{ cursor: 'pointer' }}
              key={match.buyerId}
            >
              <Card
                className={cx(
                  'u-flex  u-flex-col u-py-4 u-px-6',
                  flowspace,
                  className
                )}
                bgColorClass={tw`u-bg-opacity-60`}
                cssClasses={[
                  tw`u-rounded-[24px] u-border u-border-solid u-border-newprimary-purewhite u-backdrop-blur-[100px]`
                ]}
              >
                <div className='u-flex u-flex-row u-h-12'>
                  <div className='u-flex u-flex-row u-items-center u-gap-4'>
                    {matchImage(match.logo || match.companyLogo)}
                    <p className='lens-paragraph-small u-text-left u-text-black u-text-[14.4px] u-line-clamp-2 u-leading-[22.6px]'>{match.name || match.companyName}</p>
                  </div>
                  <div className='u-flex u-items-center u-ml-auto u-pl-1'>
                    {matchScoreIndicator(match.matchScore)}
                  </div>
                </div>
              </Card>
            </div>
          ))}
        </Card>

      )
      break
    default:
      if (useNewStyling) {
        padding = 'u-p-200 print:u-p-0'
        innerCardPadding = Array.isArray(innerCardPadding)
          ? innerCardPadding.join(' ')
          : innerCardPadding
        content = (
          <Card
            className={cx('c-flow', flowspace, padding, className)}
            bgColorClass={tw`u-bg-opacity-40`}
            cssClasses={[
              tw`u-rounded-[32px] u-border u-border-solid u-border-newprimary-purewhite u-backdrop-blur-[100px]`
            ]}
          >
            {interiorHeading && (
              <h5 className='u-flex u-items-center u-justify-center u-self-center u-py-2 u-text-newsecondary-500'>
                {interiorHeading}
              </h5>
            )}
            <Card
              className={cx(
                'u-flex u-h-[100%] u-flex-col',
                flowspace,
                innerCardPadding,
                className
              )}
              bgColorClass={tw`u-bg-opacity-60`}
              cssClasses={[
                tw`u-rounded-[24px] u-border u-border-solid u-border-newprimary-purewhite u-backdrop-blur-[100px]`
              ]}
            >
              {children}
            </Card>
          </Card>
        )
      } else {
        content = (
          <Card
            className={cx('c-flow', flowspace, padding, className)}
            bgColorClass={bgClassName ? tw`u-bg-primary-300` : bgColorClass}
          >
            {children}
          </Card>
        )
      }
      break
  }

  return content
}

export default ModuleCardBody
