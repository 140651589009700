import { Accordion } from '@unionco/components'

import { ITeamMembersModuleData } from '@unionco/alaris-app-types'
import { ModuleCard } from 'components/layout'
import BulletIcon from 'components/svg/icons/bullet/Bullet'
import ChevronIcon from 'components/svg/icons/chevron'

export interface ITeamMembersModuleProps {
  data: ITeamMembersModuleData
}

/**
 * TeamMembersModule - module component
 *
 * @returns UI for displaying people involved with the business
 * data comes from form question types within the questionnaire
 */
export const TeamMembersModule: React.FC<ITeamMembersModuleProps> = ({
  data: {
    departmentHeads,
    employees,
    founders,
    leaders,
    nonOwners
  }
}) => {
  const employeePresent = employees && employees.length > 0
  const nonOwnerPresent = nonOwners && nonOwners.length > 0
  const founderPresent = founders && founders.length > 0
  const leaderPresent = leaders && leaders.length > 0
  const departmentHeadPresent = departmentHeads && departmentHeads.length > 0

  const EmployeeData = (label: string, data: any, dataType?: 'currency' | 'percentage') => {
    const formatData = (data: any, dataType?: 'currency' | 'percentage') => {
      if (dataType === 'currency') {
        return `$${parseFloat(data).toLocaleString()}`;
      } else if (dataType === 'percentage') {
        return `${parseFloat(data).toFixed(2)}%`;
      }
      return data;
    };

    return (
      <div className='u-pt-4'>
        <p className='lens-overline-text u-text-black u-pb-3'>{label.toUpperCase()}</p>
        <div className='u-flex u-flex-row u-gap-2 u-items-baseline'>
          <BulletIcon />
          <p className='u-text-left'>{formatData(data, dataType)}</p>
        </div>
      </div>
    );
  };

  const EmployeeCard = (employee: any, index: number) => {
    return (
      <ModuleCard.Body useNewStyling={true} key={`leader_${index}`}>
        <div className='u-flex u-flex-row u-gap-2 u-items-center u-text-left'>
          {employee.profilePic && (
            <img
              className='u-w-16 u-h-16 u-rounded-full u-object-cover'
              src={employee.profilePic.url}
              alt={employee.firstName}
            />
          )}
          <div className='u-flex u-flex-col u-gap-2'>
            <h5 className='u-text-black'>
              {employee.firstName} {employee.lastName}
            </h5>
            <p className='lens-overline-text u-text-black'>{employee?.title?.toUpperCase()}</p>
          </div>
        </div>

        <div className='u-flex u-flex-col u-gap-2'>
          {employee.age && (
            EmployeeData("age", employee.age)
          )}

          {employee.jobDescription && (
            EmployeeData('job description', employee.jobDescription)
          )}

          {employee.leadershipPotential && (
            EmployeeData('leadership potential', employee.leadershipPotential)
          )}

          {employee.biography && (
            EmployeeData('biography', employee.biography)
          )}

          {/* Seller Company Fields */}
          {employee.ownership && (
            EmployeeData('ownership percentage', employee.ownership, 'percentage')
          )}

          {employee.baseSalary && (
            EmployeeData("salary", employee.baseSalary, 'currency')
          )}

          {employee.aumManaged && (
            EmployeeData("aum managed", employee.aumManaged, 'percentage')
          )}

          {employee.glidePath && (
            EmployeeData("est. time to retirement", employee.glidePath)
          )}

        </div>
      </ModuleCard.Body>
    );
  };

  const _Accordion: React.FC<any> = ({ title, data, openAccordion }) => {
    let initialOpenItems: number[] | undefined = [];
    if (openAccordion) {
      initialOpenItems = [0];
    }
    return (
      <Accordion.Main
        initialOpenItems={initialOpenItems}
      >
        <Accordion.Item
          index={0}
          className='u-flex u-flex-col u-p-2 u-items-start u-rounded-[32px] u-border u-border-newprimary-purewhite u-bg-opacity-40'
        >
          <Accordion.Button
            index={0}
            locked={false}
            className='u-flex u-w-full u-p-6 u-items-center u-justify-between u-bg-newprimary-purewhite u-bg-opacity-80 u-rounded-3xl' //TODO: The color isn't quite right

          >
            <h5 className='u-text-black'>{title}</h5>
            <Accordion.Indicator index={0} animation='upToDown'>
              <div className='u-flex u-w-[48px] u-h-[48px] u-rounded-full u-bg-[#FFF] u-items-center u-justify-center'>
                <ChevronIcon />
              </div>
            </Accordion.Indicator>
          </Accordion.Button>
          <Accordion.Drawer
            index={0}
            className='u-flex u-flex-col u-w-full u-gap-2'
          >
            <div className='u-flex u-flex-col u-pt-2 u-gap-2'>
              {data.map((employee: any, index: number) => (
                EmployeeCard(employee, index)
              ))}
            </div>

          </Accordion.Drawer>
        </Accordion.Item>
      </Accordion.Main>
    )
  }


  if (
    !departmentHeadPresent &&
    !leaderPresent &&
    !employeePresent &&
    !nonOwnerPresent &&
    !founderPresent
  )
    return <></>

  return (
    <div className='u-flex u-flex-col u-gap-2'>
      {/* SELLER TEAM MEMBERS */}
      {founderPresent && (
        _Accordion({ title: 'Leadership Team', data: founders, openAccordion: true })
      )}

      {nonOwnerPresent && (
        _Accordion({ title: 'Non-Owner Advisors', data: nonOwners })
      )}

      {employeePresent && (
        _Accordion({ title: 'Employees', data: employees })
      )}

      {/* BUYER TEAM MEMBERS */}
      {leaderPresent && (
        <div className='u-grid u-grid-cols-2 u-gap-2'>
          <h5 className='u-pb-6 u-col-span-full'>Leadership Team</h5>
          {leaders.map((employee, index) => (
            EmployeeCard(employee, index)
          ))}
        </div>
      )}

      {departmentHeadPresent && (
        _Accordion({ title: 'Department Heads', data: departmentHeads })
      )}
    </div>
  )
}

export default TeamMembersModule
