import type { FC, ReactNode } from 'react'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

import {
  IFreeResponseQuestionData,
  IQuestionSegmentData,
  IQuestionnaireData,
  TFreeReponseInputType,
  TFreeResponseAnswer
} from '@unionco/alaris-app-types'

import { debounce } from '@unionco/utils'
import {
  getCurrentQuestionData,
  submissionSuccessful
} from 'components/questionnaire/util'
import { getCurrentSegmentData } from 'utils'
import { isAdminTracking } from 'utils/tracking'
import { questionSubmitTracking } from 'utils/tracking/questionnaire'

import { TUserContext, UserContext } from 'context'

import { SidebarContext } from 'components/SidebarTemplate'

import { QuestionContext } from '../context'

interface IFreeResponseInputProps {
  data: TFreeResponseAnswer
  index: number
}

export const FreeResponseInput: FC<IFreeResponseInputProps> = ({
  data: { inputType, label, value: userValue },
  index
}) => {
  const { userType } = useContext(UserContext) as TUserContext
  const { submitAnswer } = useContext(QuestionContext)
  const { data: questionnaireData, pageLocations } = useContext(SidebarContext)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null)
  const defaultValue = String(userValue || '')
  /**
   * Input state, not used for filling the input.
   * But is used for tracking
   */
  const [inputValue, setInputValue] = useState<string>('')

  const updateValue = async (value: string) => {
    const newState = { ...questionnaireData } as IQuestionnaireData
    const currentSegment = getCurrentSegmentData(
      pageLocations,
      newState
    ) as IQuestionSegmentData
    const currentQuestion = getCurrentQuestionData(
      pageLocations,
      newState
    ) as IFreeResponseQuestionData
    currentQuestion.answers[index] = {
      ...currentQuestion.answers[index],
      value
    }
    newState.segments[pageLocations['segment']] = currentSegment
    const result = await submitAnswer(true, newState, pageLocations)
    if (submissionSuccessful(result)) {
      console.log('value in: ', value)
      setInputValue(value)
      setSubmitted(true)
    }
  }

  // Debounce time for input change
  const onChange = useMemo(() => {
    return debounce(
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (!e || !e.target) return
        const newValue = e.target.value
        updateValue(newValue)
      },
      700 // Lower debounce times cause rapid API calls that result in failure to save
    )
  }, []) // Empty dependency array ensures debounce is initialized only once

  // Save text when moving off of input box
  const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!e || !e.target) return
    const newValue = e.target.value
    updateValue(newValue) // Immediately save on blur
  }

  const debouncedTrackingHandler = useMemo(
    () =>
      debounce((value: string) => {
        if (!!value && value !== '') {
          const isAdmin = isAdminTracking(userType)
          questionSubmitTracking({
            is_admin_key: isAdmin,
            [`answer_${index}`]: value
          })
          setSubmitted(false)
        }
      }, 2000),
    [index, userType]
  )

  useEffect(() => {
    /**
     * Not sure with the debounce if the inputRef would be undefined.
     * Since it's not held in state.
     */
    if (!submitted || !inputValue) return
    debouncedTrackingHandler(inputValue)
  }, [debouncedTrackingHandler, inputValue, submitted])

  const inputStyling = 'u-rounded u-border u-border-secondary-300 u-p-2'

  const getFreeReponseInput = (inputType: TFreeReponseInputType): ReactNode => {
    switch (inputType) {
      case 'number':
      case 'dollars':
      case 'percent':
        return (
          <input
            ref={inputRef as React.RefObject<HTMLInputElement>}
            type='number'
            onChange={onChange}
            onBlur={handleBlur}
            defaultValue={String(defaultValue)}
            className={inputStyling}
          />
        )
      case 'longText':
        return (
          <textarea
            ref={inputRef as React.RefObject<HTMLTextAreaElement>}
            onChange={onChange}
            onBlur={handleBlur}
            defaultValue={String(defaultValue)}
            className={inputStyling}
          />
        )
      default:
        return (
          <input
            ref={inputRef as React.RefObject<HTMLInputElement>}
            type='text'
            onChange={onChange}
            onBlur={handleBlur}
            defaultValue={String(defaultValue)}
            className={inputStyling}
          />
        )
    }
  }

  return (
    <div className='c-flow u-flow-space-inner--200 u-text-left'>
      {label && <label>{label}</label>}
      {getFreeReponseInput(inputType)}
    </div>
  )
}

export default FreeResponseInput
