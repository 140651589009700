import type { INotesData } from '@unionco/alaris-app-types'

import { ModuleCard } from 'components'

import BulletIcon from 'components/svg/icons/bullet/Bullet'

interface INotesProps {
    notesData: INotesData
    module: string
}

export const Notes: React.FC<INotesProps> = ({
    notesData,
    module
}) => {
    const matchedNotes = notesData.items?.note?.filter(note => note.module.trim().toLowerCase() === module.trim().toLowerCase());

    return (
        <div className="u-flex u-flex-col u-gap-2 u-mt-2">
            {matchedNotes && matchedNotes.length > 0 ? (
                matchedNotes.map((note, index) => (
                    <ModuleCard.Body key={index} useNewStyling={true} innerCardPadding={['u-px-8', 'u-py-6']}>
                        <p className='lens-overline-text u-mb-2'>{note.title}</p>
                        <div className='u-flex u-items-start'>
                            <p>{note.copy}</p>
                        </div>
                    </ModuleCard.Body>
                ))
            ) : (null)}
        </div>
    );
}

export default Notes
