/** Client Demographics
 *
 *  For static y axis ticks in chart-js
 *  @see http://www.java2s.com/example/javascript/chart.js/chartjs-to-have-a-static-scale-for-yaxis.html
 *
 *  For more info on data points see documentation in types
 *
 *  Highlights are shown as Metric Cards
 *  Both "Revenue by Client Segment" and "3 Year AUM Trend"
 *  are shown as bar graphs
 *
 *  TODO:
 *  Padding polish?
 *  text styling polish
 *  Bar graph polish
 *  View more Demographics
 *  Highlight on bargraph
 *  Info component implementation
 */
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip
} from 'chart.js'

import type { IClientDemographicsData } from '@unionco/alaris-app-types'


import { ModuleCard } from 'components'


ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip
)

export interface IClientDemographicsProps {
  data: IClientDemographicsData
}

export const ClientDemographics: React.FC<IClientDemographicsProps> = ({
  data: {
    assetsUnderManagement,
    annualRevenueMix,
    ebitda,
    hhsPerAumRange,
    highlights,
    info,
    revenueData,
    threeYearAumTrend,
    threeYearGrowthMix,
    title,
    trendingRevenueMix,
    clientProfileAndSegmentationParagraphs,
    distributionOfAssets,
    generationalSummaryByAUM
  }
}) => {
  const noMainData =
    (!highlights || highlights.length === 0) &&
    revenueData === null &&
    threeYearAumTrend === null

  const data = [
    hhsPerAumRange,
    trendingRevenueMix,
    threeYearGrowthMix,
    assetsUnderManagement,
    annualRevenueMix,
    ebitda,
    distributionOfAssets,
    generationalSummaryByAUM
  ]

  const noViewMoreData = data.every((item) => {
    if (item === null || item === undefined) return true
    if (typeof item === 'string' || Array.isArray(item)) {
      return item.length === 0
    }
    return false
  })

  if (noMainData && noViewMoreData) return <></>

  return (
    <div>
      <h5 className='u-pb-6'>Client Profile & Segmentation</h5>
      <div className='u-flex u-flex-col u-gap-6'>
        {/* Highlights Data */}
        {highlights && highlights.length > 0 && (
          <ModuleCard.Body
            type={'metric'}
            metricContent={highlights}
            interiorHeading='Highlights'
          />
        )}

        {/* Revenue by Client Segment Data */}
        {revenueData && (
          <ModuleCard.Body
            interiorHeading='Revenue by Client Segment'
            type={'bar-chart'}
            chartContent={revenueData}
          />
        )}
        {/*Yearly AUM Trend Data */}
        {threeYearAumTrend && (
          <ModuleCard.Body
            interiorHeading='Yearly AUM Trend'
            type={'bar-chart'}
            chartContent={threeYearAumTrend}
          />
        )}

        {/* TODO: Revisit to put these side by side. Added to backlog as lower priority */}
        {/* Number of Households by AUM Data */}
        {hhsPerAumRange && (
          <ModuleCard.Body
            interiorHeading='Number of Households by AUM'
            type={'bar-chart'}
            chartContent={hhsPerAumRange}
            yAxisFormat='label'
          />
        )}

        {/* Distribution of Assets Data */}
        {distributionOfAssets && (
          <ModuleCard.Body
            interiorHeading='Distribution of Assets'
            type={'bar-chart'}
            chartContent={distributionOfAssets}
          />
        )}

        {/* Generational Summary by AUM Data */}
        {generationalSummaryByAUM && (
          <ModuleCard.Body
            interiorHeading='Generational Summary by AUM'
            type={'table'}
            tableContent={generationalSummaryByAUM}
            columnHeaders={['Generational Range', 'Percentage of RIA AUM']}
          />
        )}

        {/* Trending Revenue Mix Data */}
        {trendingRevenueMix && (
          <ModuleCard.Body
            interiorHeading='Trending Revenue Mix'
            type={'line-chart'}
            chartContent={trendingRevenueMix}
          />
        )}

        {/* EBITDA Data */}
        {ebitda && (
          <ModuleCard.Body
            interiorHeading='EBITDA'
            type={'bar-chart'}
            chartContent={ebitda}
            indexAxis='y'
            yAxisFormat='label'
            xAxisFormat='currency'
          />
        )}
      </div>
    </div>
  )
}

export default ClientDemographics
