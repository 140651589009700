import { useBreakAtSize } from '@unionco/components'
import React, { useState } from 'react'

import { Modules, TModuleData } from 'components'
import { INotesData } from '@unionco/alaris-app-types'

interface IDashboardMainSidebarProps {
  modules: TModuleData[]
}

export const ModulesLayoutSideBar: React.FC<IDashboardMainSidebarProps> = ({
  modules
}) => {
  const { aboveBreakPoint } = useBreakAtSize('lg')
  const [activeTab, setActiveTab] = useState<string>(
    'Overview'
  )

  const notesData = modules.find((mod) => mod.moduleType === 'Notes') as INotesData | undefined;

  // Grouping logic for modules by tab and position
  const modulesByTab: {
    [key: string]: { main: TModuleData[]; right: TModuleData[] }
  } = {}
  modules.forEach((mod) => {
    // Default values for tab and position when not set in Strapi
    const tabValue = mod.tab || 'Overview'
    const positionValue = mod.position || 'Main'

    // Exclude modules with tabValue equal to 'None'
    if (tabValue === 'None') return;

    if (tabValue === 'All') {
      // If the tab value is All then include this module in every tab
      Object.keys(modulesByTab).forEach((key) => {
        if (!modulesByTab[key]) {
          modulesByTab[key] = { main: [], right: [] }
        }
        if (positionValue === 'Main') {
          modulesByTab[key].main.push(mod)
        } else if (positionValue === 'Right Side') {
          modulesByTab[key].right.push(mod)
        }
      })
    } else {
      // If the tab value is not All then include this module only in the specified tab
      if (!modulesByTab[tabValue]) {
        modulesByTab[tabValue] = { main: [], right: [] }
      }
      if (positionValue === 'Main') {
        modulesByTab[tabValue].main.push(mod)
      } else if (positionValue === 'Right Side') {
        modulesByTab[tabValue].right.push(mod)
      }
    }
  })

  const tabKeys = Object.keys(modulesByTab)

  return (
    // Tab styling
    <div>
      <div className='u-mb-12 u-flex u-border-b u-border-newsecondary-100'>
        {tabKeys.map((tabValue) => (
          <button
            key={tabValue}
            // Ternary operator to determine different styling when tab is active
            className={`
              lens-small-paragraph-text u-mr-2 u-cursor-pointer u-px-4 u-py-2
              ${activeTab === tabValue
                ? 'u-border-b-4 u-border-newprimary-700'
                : 'u-text-newsecondary-500'
              }
            `}
            onClick={() => setActiveTab(tabValue)}
          >
            {tabValue}
          </button>
        ))}
      </div>

      {/* Modules layout */}
      {tabKeys.map((tabValue) => (
        <div
          key={tabValue}
          // Ternary operator to hide modules not in the active tab
          className={`${activeTab === tabValue ? 'u-block' : 'u-hidden'}`}
        >
          <div className='c-withSidebar c-withSidebar--rtl [--sidebar-gutter:theme(spacing.600)] print:u-flex print:u-flex-col'>
            {/* Modules in the main part of the layout with position set to Main */}
            <div className='b-ProfileSideBar | c-flow'>
              {modulesByTab[tabValue].main.map((mod, index) => {
                const isNotesModule = mod === notesData;
                return (
                  <div
                    key={`MainModule_${index}`}
                    className={` ${isNotesModule ? 'u-flow-space--100' : 'u-flow-space--800'}`}
                  >
                    <Modules data={mod} notesData={notesData} />
                  </div>
                );
              })}
            </div>
            {/* Modules on the right side of the layout with position set to Right Side */}
            <div className='c-flow | u-flow-space--600'>
              {modulesByTab[tabValue].right.map((mod, index) => (
                <Modules data={mod} notesData={notesData} key={`SideModule_${index}`} />
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ModulesLayoutSideBar
