import { IFinancialSnapshotData } from '@unionco/alaris-app-types'

import { capitalizeAndSplit, formatCurrency } from '@unionco/alaris-utils'

import { ModuleCard } from 'components'

export interface IFinancialSnapshotProps {
  data: IFinancialSnapshotData
}

export const FinancialSnapshot: React.FC<IFinancialSnapshotProps> = ({
  data: {
    headings,
    info,
    items,
    title,
    paragraphs
  },
}) => {
  if (!items || items.length === 0) return <></>

  const highlightBgColor = 'u-bg-primary-300'
  title = title || 'Financial Snapshot'

  const formatTitle = (key: string) => {
    switch (key) {
      case 'opex':
      case 'eboc':
      case 'ebitda':
        return key.toUpperCase()
      case 'totalRevenue':
      case 'grossRecurringRevenue':
      case 'grossNonRecurringRevenue':
      case 'proposedRetainedComp':
      default:
        return capitalizeAndSplit(key)
    }
  }

  const titleOrder = ['grossRecurringRevenue', 'grossNonRecurringRevenue', 'totalRevenue', 'opex', 'eboc', 'proposedRetainedComp', 'ebitda'];
  let sortedItems;
  if (items && items.length > 0) {
    sortedItems = items.sort((a, b) => titleOrder.indexOf(a.title) - titleOrder.indexOf(b.title));
  }
  return (
    <div>
      <h5 className="u-self-start u-pb-6">{title}</h5>
      <ModuleCard.Body useNewStyling={true} innerCardPadding={'u-p-0'}>
        {sortedItems && sortedItems.length > 0 && (
          <>
            <ModuleCard.InfoHeader
              info={info}
              trackingName='financial snapshot module'
            />
            <table className='u-table-fixed'>
              <thead className='u-font-bold u-text-left u-pl-4'>
                <tr>
                  <th className='u-py-3 u-pl-4 u-text-left u-font-semibold u-text-newsecondary-500'>{ }</th>
                  <th
                    className={`u-rounded-t u-py-3 u-pl-4 u-text-primary-500 ${highlightBgColor} u-font-semibold u-border`}
                  >
                    {headings[0]}
                  </th>
                  <th className='u-py-3 u-pl-4 u-font-semibold u-border'>{headings[1]}</th>
                  <th className='u-py-3 u-pl-4 u-font-semibold u-border'>{headings[2]}</th>
                </tr>
              </thead>
              <tbody>
                {sortedItems
                  ? sortedItems.map((row, index) => {

                    const { title, values } = row
                    const rowIndex = index
                    return (
                      <tr
                        key={`FinancialSnapShot_row_${title}_${index}`}
                      >
                        <th className='u-p-4 u-text-left u-font-semibold u-border u-text-black u-text-5'>
                          {formatTitle(title)}
                        </th>
                        {values.map((v, index) => {
                          const bgColor =
                            index === 0 ? `${highlightBgColor}` : ''
                          return (
                            <td
                              key={`FinancialSnapShot_item_${rowIndex}_${index}`}
                              className={`u-w-[22.5%] u-font-semibold u-text-newsecondary-500 ${bgColor} u-border u-text-left u-pl-5`}
                            >
                              {formatCurrency(v)}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })
                  : null}
              </tbody>
            </table>
          </>
        )}
      </ModuleCard.Body>
      {/** Paragraphs displayed in one card beneath snapshot */}
      <div className='u-pt-2'>
        <ModuleCard.Body useNewStyling={true} innerCardPadding={['u-px-8', 'u-py-8']}>
          {paragraphs &&
            paragraphs.map((paragraph, index) => (
              <div>
                <p className='lens-overline-text u-pb-1' key={`FinancialSnapshot_paragraph_${index}`} >
                  {paragraph.title}
                </p>
                <p className={index === paragraphs.length - 1 ? '' : 'u-pb-6'}>
                  {paragraph.description}
                </p>
              </div>
            ))}
        </ModuleCard.Body>
      </div>
    </div>
  )
}

export default FinancialSnapshot
