import React from 'react'

import { IAutonomyProfileModuleData } from '@unionco/alaris-app-types'

import { ModuleCard, TitleAndCopy, Video, CheckMarkListItem } from 'components'

interface IAutonomyProfileModuleProps {
    data: IAutonomyProfileModuleData
}

export const AutonomyProfileModule: React.FC<
    IAutonomyProfileModuleProps
> = ({ data }) => {
    if (!data.items) return <></>
    let { video, title, checkboxes } = data?.items
    title = title || 'Autonomy Profile'
    return (
        <div>
            {video && (
                <div>
                    <h5 className="u-self-start u-pb-6">{title}</h5>
                    <ModuleCard.Body
                        type="description-video"
                        videoContent={data.items}
                        key={`AutonomyProfile`}
                    />
                </div>
            )}
            {(checkboxes?.length > 0 || !video) && (
                <div className={video ? "u-mt-2" : ""}> {/* Adds top margin only if video is present */}
                    {!video && <h5 className="u-self-start u-pb-6">{title}</h5>}
                    <ModuleCard.Body className="u-text-left" useNewStyling={true}>
                        <div className="c-autoGrid">
                            {checkboxes.sort((a, b) => a.title.length - b.title.length).map((item, index) => {
                                return (
                                    <CheckMarkListItem
                                        className={'u-py-2 print:u-py-0'}
                                        data={item}
                                        key={`CheckMarkListItem_${index}`}
                                    />
                                )
                            })}
                        </div>
                    </ModuleCard.Body>
                </div>
            )}
        </div>
    )
}

export default AutonomyProfileModule