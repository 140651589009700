/** Question Component
 * Returns different question types
 */
import axios, { AxiosError, AxiosResponse } from 'axios'
import { useContext, useEffect, useMemo } from 'react'

import type {
  IDocumentUploadQuestionData,
  IFreeResponseQuestionData,
  ILocationsIndexesData,
  IPhaseSummaryData,
  IQuestionSegmentData,
  IQuestionnaireData,
  IRangeQuestionData,
  ISearchQuestionData,
  ISelectMultipleQuestionData,
  ISelectOneQuestionData,
  IYesNoQuestionData,
  TFormQuestionData,
  TFormQuestionTypes,
  TQuestionType,
  TSidebarSegmentData
} from '@unionco/alaris-app-types'

import { API_BASE } from '@appConstants'

import {
  TriggerQuestionTypes,
  getProgressionIndexes,
  setQuestionnaireStatus,
  setSegmentStatus
} from '@unionco/alaris-utils'
import { debounce } from '@unionco/utils'
import { getCurrentSegmentData } from 'utils'
import { isAdminTracking } from 'utils/tracking'
import { questionPageLoadTracking } from 'utils/tracking/pageLoad'

import {
  IAMContext,
  PreAuthGlobalsContext,
  TClientContext,
  TIAMContext,
  UserContext
} from 'context'

import { NotesPanel, SegmentHeader, SidebarContext } from 'components'

import QuestionControls from '../controls'
import FreeResponseQuestion from './FreeResponse'
import { SearchQuestion } from './Search'
import { QuestionContext } from './context'
import { DocumentUploadQuestion } from './documentUpload'
import QuestionNumberIndicator from './numberIndicator'
import RangeQuestion from './range'
import SelectMultipleQuestion from './selectMultiple'
import SelectOneQuestion from './selectOne'
import { TeamMembersQuestion } from './team'
import YesNoQuestion from './yesNo'

export type TAxiosSubmitReturn =
  | AxiosResponse<unknown, unknown>
  | AxiosError<unknown, unknown>
export type TSubmitReturn = Promise<TAxiosSubmitReturn>

export type TSubmitAnswer = (
  answered: boolean | null,
  d: IQuestionnaireData,
  pl: ILocationsIndexesData
) => TSubmitReturn

interface IQuestionProps {
  data: IQuestionSegmentData
  questionnaireTitle: string
  segmentTitle: string
}

export const Question: React.FC<IQuestionProps> = ({
  data: { questions },
  questionnaireTitle,
  segmentTitle
}) => {
  // Context Data
  const { pageLoaded, setPageLoadedTrue } = useContext(PreAuthGlobalsContext)
  const { jwt, userType } = useContext(UserContext) as TClientContext
  const { IAMData, setIAM } = useContext(IAMContext) as TIAMContext
  const { id, impersonationMode, progression } = IAMData
  const {
    pageLocations,
    refetch: refetchTemplateData,
    setSidebarTemplateData,
    setSidebarTemplateSidebarData,
    sidebarData
  } = useContext(SidebarContext)
  const { question: questionIndex } = pageLocations

  const numOfQuestions = questions.length
  const currentQuestion = questions[questionIndex as number]

  const { branchTriggers, key, questionSubText, questionText, questionType } =
    currentQuestion

  /**
   * Questionnaire Page Load Tracking
   *
   * If not in questionnaire it returns immeditately
   */
  useEffect(() => {
    if (pageLoaded) return
    questionPageLoadTracking({
      questionnaire_title: questionnaireTitle,
      segment_title: segmentTitle,
      question_text: questionText,
      is_admin_key: isAdminTracking(userType),
      page: 'questionnaire step'
    })
    setPageLoadedTrue()
  }, [
    questionnaireTitle,
    pageLoaded,
    questionText,
    segmentTitle,
    setPageLoadedTrue,
    userType
  ])

  /**
   * Some addiontal notes exist in the ./utils for how we can
   * further adjust debounce times if we need to.
   */
  const debounceTime = currentQuestion && branchTriggers ? 750 : 1500

  const refetchTemplateDataDebounced = useMemo(
    () => debounce(refetchTemplateData, debounceTime),
    [debounceTime, refetchTemplateData]
  )

  if (!currentQuestion) return <></>

  const phaseSummary = sidebarData as IPhaseSummaryData
  const phaseSteps = phaseSummary.steps

  const submitAnswer: TSubmitAnswer = async (answered, data, pageLocations) => {
    const {
      question: questionIndex,
      section: sectionIndex,
      segment: segmentIndex
    } = pageLocations
    // Update local / internal state
    const newState = { ...data } as IQuestionnaireData
    const segment = getCurrentSegmentData(
      pageLocations,
      newState
    ) as IQuestionSegmentData
    if (answered !== null) {
      segment.questions[questionIndex as number].answered = answered
    }

    const currentQuestionData = segment.questions[questionIndex as number]

    const { phase: phaseKey } = progression
    const stepKey = phaseSteps[sectionIndex].key

    /** Update main data
     *
     */
    segment.status = setSegmentStatus(segment)
    newState.status = setQuestionnaireStatus(newState)
    setSidebarTemplateData(newState)

    /** Update Sidebar Data
     *
     */
    const newSummary = { ...phaseSummary }
    const segmentSummary = getCurrentSegmentData(
      pageLocations,
      newSummary.steps[sectionIndex]
    ) as TSidebarSegmentData
    segmentSummary.status = setSegmentStatus(segment)
    phaseSummary.steps[sectionIndex].status = setQuestionnaireStatus(newState)
    setSidebarTemplateSidebarData(newSummary)

    /**
     * debounce the server submission?
     * Or debounce the reload?
     * Add submission back into the continue button
     */
    const result: TSubmitReturn = axios
      .put(
        `${API_BASE}/api/question`,
        {
          userId: id,
          phase: {
            key: phaseKey
          },
          step: {
            key: stepKey,
            index: sectionIndex
          },
          segment: {
            key: segment.key,
            index: segmentIndex
          },
          questionData: currentQuestionData,
          questionIndex: questionIndex
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        }
      )
      .then((res: AxiosResponse<unknown, unknown>) => {
        // If the question is a trigger question, we need to reload
        // the template data so the updated questions are available immediately
        // without a page refresh
        const triggerType =
          currentQuestionData.triggerQuestionType &&
          currentQuestionData.triggerQuestionType.split('.')[0]
        const shouldTriggerReload =
          triggerType === TriggerQuestionTypes.ASSESSMENT_GENERIC ||
          triggerType ===
          TriggerQuestionTypes.ASSESSMENT_MULTI_SELECT_TO_RANGE ||
          triggerType === TriggerQuestionTypes.MULTI_SELECT_TO_RANGE ||
          triggerType === TriggerQuestionTypes.SEARCH_TO_FREE_RESPONSE ||
          currentQuestionData.branchTriggers ||
          currentQuestionData.podTriggers
        if (shouldTriggerReload) {
          refetchTemplateDataDebounced()
        }

        return res
      })
      .catch((error: AxiosError) => {
        console.error('Error:', error)
        return error
      })

    /**
     * If question submission worked
     * check if progression needs to be updated
     */
    const {
      question: progQuestionIndex,
      section: progStepIndex,
      segment: progSegmentIndex
    } = getProgressionIndexes(phaseSteps, progression)
    // Only update if section and segment are equal or higher
    if (sectionIndex >= progStepIndex && segmentIndex >= progSegmentIndex) {
      const hasQuestions = questionIndex && progQuestionIndex
      if (
        (hasQuestions && (questionIndex as number) > progQuestionIndex) ||
        !hasQuestions
        // TODO: Add logic for cleared answers?
      ) {
        // The question index is higher in the segment so update
        // OR There is no question in the segment so update
        const updatedProgression = {
          userId: id,
          phase: phaseKey,
          step: stepKey,
          segment: segment.key,
          question: currentQuestionData.key
        }
        const progResult = await axios.post(
          `${API_BASE}/api/user-progression`,
          updatedProgression,
          {
            headers: {
              Authorization: `Bearer ${jwt}`
            }
          }
        )

        /**
         * Maybe update local state first
         */
        if (progResult.status === 200) {
          setIAM({ ...IAMData, ...updatedProgression })
        }
      }
    }

    return result
  }

  const QuestionData = (questionType: TQuestionType) => {
    switch (questionType) {
      case 'selectOne':
        return {
          instruction: 'Select one.',
          component: (
            <SelectOneQuestion
              key={key}
              data={currentQuestion as ISelectOneQuestionData}
            />
          )
        }
      case 'search': {
        return {
          instruction: 'Select all that apply.',
          component: (
            <SearchQuestion
              key={key}
              data={currentQuestion as ISearchQuestionData}
            />
          )
        }
      }
      case 'selectMultiple': {
        return {
          instruction: 'Select all that apply.',
          component: (
            <SelectMultipleQuestion
              key={key}
              data={currentQuestion as ISelectMultipleQuestionData}
            />
          )
        }
      }
      case 'yesNo':
        return {
          instruction: 'Select one.',
          component: (
            <YesNoQuestion
              key={key}
              data={currentQuestion as IYesNoQuestionData}
            />
          )
        }
      case 'range':
        return {
          instruction: 'Drag the slider to the appropriate value',
          component: (
            <RangeQuestion
              key={key}
              data={currentQuestion as IRangeQuestionData}
            />
          )
        }
      case 'freeResponse':
        return {
          instruction: '',
          component: (
            <FreeResponseQuestion
              key={key}
              data={currentQuestion as IFreeResponseQuestionData}
            />
          )
        }
      case 'teamMember':
      case 'ownerForm':
      case 'contractorForm':
      case 'nonOwnerForm':
      case 'departmentHeadForm':
      case 'basicTeamForm':
      case 'leaderForm':
        return {
          instruction: '',
          component: (
            <TeamMembersQuestion
              key={key}
              data={currentQuestion as TFormQuestionData}
              questionType={questionType as TFormQuestionTypes}
            />
          )
        }
      case 'documentUpload':
        return {
          instruction: 'Use the button below to upload relevant documents',
          component: (
            <DocumentUploadQuestion
              key={key}
              data={currentQuestion as IDocumentUploadQuestionData}
            />
          )
        }
      default:
        return {
          instruction: '',
          component: <></>
        }
    }
  }

  const QuestionObj = QuestionData(questionType)
  const questionEyebrowText = `Question ${(questionIndex as number) + 1
    } of ${numOfQuestions}`
  const indicator = numOfQuestions > 1 && (
    <QuestionNumberIndicator
      activeIndex={questionIndex as number}
      numOfQuestions={numOfQuestions}
    />
  )

  const questionContext = {
    currentQuestion,
    submitAnswer
  }

  return (
    <QuestionContext.Provider value={questionContext}>
      <div className='c-flow | u-bg-white u-p-400 u-py-600 u-text-center lg:u-p-500 xl:u-p-700 xl:u-py-700'>
        <SegmentHeader
          eyebrow={questionEyebrowText}
          indicator={indicator}
          header={questionText}
          subtext={questionSubText}
          supportText={QuestionObj.instruction}
          tiedQuestionText={currentQuestion.impersonationTiedQuestionSummary}
        />
        {QuestionObj.component}
        <QuestionControls submitCallback={submitAnswer} />
      </div>
      {impersonationMode && (
        <NotesPanel data={{ notes: currentQuestion.adminNotes }} />
      )}
    </QuestionContext.Provider>
  )
}

export default Question
